<template>
  <div>
    <div class="contentBox">
      <div class="title">
        <div class="titleText">
          <div>报价编号： {{ header.quotation_no | textFormat }} | {{header.inquiry_user | textFormat}}（{{header.inquiry_user_company | textFormat}}）
            <span class="fr titleright">报价日期： {{ header.create_time | secondFormat("LL")  }} </span>
          </div>
        </div>
      </div>
    </div>
    <shipMsg :header='header' pageType='share'  v-loading="loading"></shipMsg>
     <div style="height:10px;background:#f2f2f2"></div>
    <div class="contentBox" style="border: 1px solid #DCDFE6;;border-top:0">
      <el-tabs v-model="tabs_name" type='card'>
        <el-tab-pane label="费用明细" name="0"></el-tab-pane>
        <!-- <el-tab-pane label="分享记录" name="1"></el-tab-pane> -->
        <el-tab-pane label="访问记录" name="2"></el-tab-pane>
      </el-tabs>
      <!-- 费用明细 -->
      <div v-if="tabs_name==0">
        <div style="display:flex;justify-content: space-between;padding:0 24px">

            <div class="unnoMsg" v-if="header.is_dangerous">
              <div class="no">UNNO: <span class="color">{{ header.un_no | textFormat }}</span></div>
              <div class="psn">PSN:{{ header.pscn | textFormat }}</div>
              <div class="class">
                Class: {{ header.classify | textFormat }}
              </div>
              <div class="PG">PG: {{ header.packing_group | textFormat }}</div>
            </div>
            <div v-else></div>
            <div class="seaMoney">
              <el-button type="primary" size="small" style="background:#37AB0E;border-color:#37AB0E" @click="handle_to_share">分享报价</el-button>
              <el-button type="primary" size="small" @click="hadle_to_copy">复制报价</el-button>
            </div>
        </div>


        <div class="moneyList" v-loading="loading">
          <div class="seaItem nomargin_top">
            <div class="itemTitleBox" style="padding-top:20px">
              <div>
                 <img src="https://ym-attached-file.oss-cn-shanghai.aliyuncs.com/miniProgram/ship2.png" style="width:30px;height:30px;margin-right:10px" alt="">
                <span class="itemTitle">海运费：</span>
                <span class="itemMOney"
                  >{{ header.currency }}

                  <span class="price">{{current_code||'USD'  }} {{ seaTotal }}</span
                  ></span
                ><span class="itemTips"></span>
              </div>
            </div>
            <div class="itemContent">
              <div class="tableLine tableTitle">
                <div class="table1"></div>
                <div class="table2">币种</div>
                <div class="table3">计量单位</div>
                <div class="table4">单价</div>
              </div>
              <div
                class="tableLine"
                v-for="(item, index) in header.container"
                :key="index"
                style="border-bottom: 1px solid #EBEEF5;"
              >
                <div  v-if="item.number && item.price!=0" >
                  <div class="table1">海运费</div>
                  <div class="table2">{{ item.code }}</div>
                  <div class="table3">{{ item.name }}</div>
                  <div class="table4 price" >
                    {{ item.price }}
                  </div>
                </div>

              </div>
            </div>
          </div>
          <costComp style="margin-top:20px" :costList='costList' @open_close="open_close"></costComp>
        </div>
      </div>
      <!-- 分享记录+ 访问记录 -->
      <div class="share_box" v-if="tabs_name==1">
        <div>共分享{{ share_count }}次</div>
         <el-table
           class="share_table"
          :loading='share_loading'
          :data="shareArr"
          style="width: 100%"
        >
          <el-table-column
            v-if="tabs_name==1"
            prop="info"
            label="分享备注">
            <div  slot-scope="scope">{{ scope.row.info | textFormat}}</div>
          </el-table-column>

          <el-table-column
            v-if="tabs_name==1"
            prop="time_info"
            label="日期"
            width="180"
          >
            <template slot-scope="scope" >
              <span>{{ scope.row.time_info |secondFormat("LL") }}</span>
            </template>
          </el-table-column>
        </el-table>
        <div style="text-align: right;">
          <el-pagination
            :pager-size="10"
            @current-change="handleChange_share"
            :hide-on-single-page="true"
            layout="prev, pager, next"
            :total="share_total">
          </el-pagination>
        </div>


      </div>
      <div class="share_box" v-if="tabs_name==2">
         <el-table
          class="share_table"
          :loading='share_loading'
          :data="shareArr"
          style="width: 100%"
        >
          <el-table-column  prop="time_info" label="访问信息" >
            <div  slot-scope="scope" class="vist_msg">
              <span class="textOverflow" v-if="scope.row.company_name_zh||scope.row.company_name_en" :title="scope.row.company_name_zh||scope.row.company_name_en">{{scope.row.company_name_zh||scope.row.company_name_en||'-'}}</span>

              <span v-if="scope.row.mobile">{{scope.row.user_name_zh||scope.row.user_name_zh||'-'}}</span>
              <span v-else>游客</span>

              <el-divider direction="vertical" v-if="(scope.row.user_name_zh||scope.row.user_name_en)&&scope.row.mobile"></el-divider>
              <span>{{scope.row.mobile||'-'}}</span>
            </div>
          </el-table-column>
          <el-table-column prop="time_info" label="日期" width="180">
            <div  slot-scope="scope">{{ scope.row.created_time|secondFormat('LL') }}</div>
          </el-table-column>
        </el-table>
        <div style="text-align: right;">
          <el-pagination
            :pager-size="10"
            @current-change="handleChange_share"
            :hide-on-single-page="true"
            layout="prev, pager, next"
            :total="share_total">
          </el-pagination>
        </div>


      </div>
    </div>
    <div class="page_tips"  v-if="tabs_name==0">
      <i class="el-icon-warning"></i>
      <span>订舱前请确认好准确的数据，订舱后因数据不对或其他任何原因退关都会产生空舱费。</span>
    </div>
  </div>
</template>

<script>
import shipMsg from "~bac/components/ymp/shipMsg";
import costComp from "~bac/components/ymp/costComp";
export default {
  name: "myofferDetail",
  components:{
    costComp,
    shipMsg
  },
  data() {
    return {
      checkedArr: ["20GP"],
      header: {},
      loading: false,
      dialogVisible: false,
      costList: [],
      value: false,
      volume: 0,
      weight: 1000,
      number: 1,
      seaTotal: 0,
      unno: "",
      user_type: 1,
      unnoMsg: {},
      tabs_name:0,
      shareArr:[],
      share_start:1,
      share_total:0,
      share_count:0,
      share_loading:false,
      current_code:"",
    };
  },
  mounted() {
    this.getHeader();
    this.get_visit_list()
  },
  watch:{
    tabs_name(e){
      if(e==0){
        this.$log({
          type: 1,
          topic: "ymp.workbench",
          id: "ymp.workbench.offer.detail.cost",
          content: JSON.stringify({
            id: this.$route.query.id || ''
          }),
        });
        return
      }
      this.$log({
        type: 1,
        topic: "ymp.workbench",
        id: "ymp.workbench.offer.detail.visit",
        content: JSON.stringify({
          id: this.$route.query.id || ''
        }),
      });
      this.shareArr=[]
      this.share_start=1
      this.share_total=0
      this.get_visit_list()
    }
  },
  computed: {},
  methods: {
    open_close(e){ //打开 关闭
      this.costList[e.key].open=!this.costList[e.key].open
      this.costList=JSON.parse(JSON.stringify(this.costList))
    },

    handleChange_share(e){
      this.share_start=e
      this.get_visit_list()
    },
    changeShow(item){
        item.open = !item.open
        this.$forceUpdate()
    },
    handle_to_share(){
      this.$log({
        type: 1,
        topic: "ymp.workbench",
        id: "ymp.workbench.offer.detail.share",
        content: JSON.stringify({
          id: this.$route.query.id || ''
        }),
      });
      let routeData = this.$router.resolve({
        query: { id: this.$route.query.id },
        path: "/share",
      });
      window.open(routeData.href, "_blank");


      // this.$router.push({
      //   path:"/console/myoffer/share",
      //   query:{
      //     id: this.$route.query.id
      //   }
      // })
    },
    hadle_to_copy(){
      this.$log({
        type: 1,
        topic: "ymp.workbench",
        id: "ymp.workbench.offer.detail.copy",
        content: JSON.stringify({
          id: this.$route.query.id || ''
        }),
      });
      let routeData = this.$router.resolve({
        query: { id: this.$route.query.id },
        path: "/copy",
      });
      window.open(routeData.href, "_blank");

      // this.$router.push({
      //   path:"/console/myoffer/copy",
      //   query:{
      //     id: this.$route.query.id
      //   }
      // })


    },
    handleClose() {},
    async getHeader() {
      this.loading = true;
      let params = {
        quotation_id: this.$route.query.id,
      };
      let result = await Promise.all([
        this.$store.dispatch("baseConsole/offer_info", params),
      ]);
      this.loading = false;
      if (result[0].success) {
        let data = result[0].data;
        data.week = [];
        data.abs = Math.abs(data.price_lcl_dangerous);
        data.shipping_date_arr = data.shipping_date.split(",");

        data.shipping_date_arr.forEach((ee) => {
          if (ee == 1) {
            data.week.push("周一");
          }
          if (ee == 2) {
            data.week.push("周二");
          }
          if (ee == 3) {
            data.week.push("周三");
          }
          if (ee == 4) {
            data.week.push("周四");
          }
          if (ee == 5) {
            data.week.push("周五");
          }
          if (ee == 6) {
            data.week.push("周六");
          }
          if (ee == 7) {
            data.week.push("周日");
          }
        });
        data.container = JSON.parse(data.container)
        this.current_code=data.container[0].code
        let money = 0;
        data.container.forEach((ee) => {
          if (ee.number) {
            money = money + ee.price*1;
          }
        });
        this.costList = JSON.parse(data.extend);
        this.costList.forEach((ee, index) => {
          ee.canCopy = true;
          ee.open = true;
          if (index > 0) {
            ee.open = false;
          }
        });
        this.seaTotal = parseInt(money);
        this.header = data;
        this.effect_cost()
      }
    },
    effect_cost(){
      // header.container
      let checkedArr=[]
      for(var item of this.header.container){
        if(item.number && item.price!=0){
          checkedArr.push(item.name)
        }
      }


      for(var item of this.costList){
        item.group_total_price={}
        for(var it of item.data_list){
          if(it.unit == 5||it.measure=="GP"||it.measure=="箱"){ //---------------------------------------------箱

            for(var key of checkedArr){
              if(key=='20GP'){
                if(item.group_total_price[it.code]){
                  item.group_total_price[it.code]+=Number(it.GP20)
                }else{
                  item.group_total_price[it.code]=Number(it.GP20)
                }
              }
              if(key=='40GP'){
                if(item.group_total_price[it.code]){
                  item.group_total_price[it.code]+=Number(it.GP40)
                }else{
                  item.group_total_price[it.code]=Number(it.GP40)
                }
              }
              if(key=="40HQ"){
                  if(item.group_total_price[it.code]){
                    item.group_total_price[it.code]+=Number(it.HC40)
                  }else{
                    item.group_total_price[it.code]=Number(it.HC40)
                  }
              }
            }
          }else if(it.code==""){  // 没有币种得不计算

          }else{  // 票
            if(item.group_total_price[it.code]){
              item.group_total_price[it.code]+=Number(it.price)
            }else{
              item.group_total_price[it.code]=Number(it.price)
            }
          }
        }
      }
      this.costList=JSON.parse(JSON.stringify(this.costList))
    },
    // 获取记录
    async get_visit_list(){
      this.share_loading=true
      let params={
        quotation_id: this.$route.query.id,
        start:this.share_start-1,
        limit:10,
        nopage:0
      }
      let url=""
      if(this.tabs_name==1){  //分享记录
        url='baseConsole/quotation_share_query'
      }else{    // 访问记录
        url='baseConsole/quotation_visit_query'
      }
      let res=await this.$store.dispatch(url,params)
      this.share_total=res.count
      if(this.tabs_name==1){
        this.share_count=res.share_count
      }
      this.shareArr=res.data
      this.share_loading=false
    },
    handleLogin() {
      this.$GLOBALEVENT.$emit("SHOWGLOBALLOGIN");
    },
  },
};
</script>

<style scoped lang="less">
.nomargin_top{
  margin-top:0px !important;
}
/deep/ .el-table__empty-block {
  min-height: 200px;
}
.vist_msg{
  display: flex;
  align-items: center;
  span:first-child{
    max-width: 300px;
    margin-right: 40px;
  }
}
.share_box{
  padding: 0 24px;
}
/deep/.el-tabs__item.is-active {
  color:#91BE42;
}
.contentBox {
  // padding: 0 30px;
}
/deep/ .el-tabs__item.is-active{
  color: #91BE42;
}
.dingcang {
  display: flex;
  align-items: center;
  div{
    height: 22px;
    font-size: 16px;
    font-weight: 800;
    color: #0C263F;
    line-height: 22px;
  }
}
.unnoMsg {
  display: flex;
  align-items: center;
  div{
    margin-right: 40px;
    height: 20px;
    font-size: 14px;
    font-weight: 400;
    color: #3C5058;
    line-height: 20px;
  }
  .color{
    color: #91BE42;
  }
}
.unno {
  display: flex;
  align-items: center;
}
.mohu {
  color: transparent;
  -moz-user-select: none; //火狐
  -webkit-user-select: none; //webkit浏览器（Chrome，Opera，Safari等）
  -ms-user-select: none; //IE10
  -khtml-user-select: none; //早期浏览器
  user-select: none;
  text-shadow: #000 0 0 8px;
}
.moneyList {
  background: #fff;
  padding: 24px;
  padding-top:0;
  .seaItem {
    margin-top:40px;
    .itemTitleBox {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .btn {
        font-size: 16px;
        color: #999;
        cursor: pointer;
      }
    }
    .itemTitleBox {
      padding-top: 40px;
      padding-bottom: 24px;
    }
    .el-icon-ship {
      display: inline-block;
      width: 30px;
      height: 30px;
      background: #ff7727;
      border-radius: 50%;
      text-align: center;
      line-height: 30px;
      font-size: 16px;
      color: #fff;
      margin-right: 10px;
    }
    .itemTitle {
      font-weight: 900;
      font-size: 18px;
    }
    .itemMOney {
      font-weight: 900;
      font-size: 18px;
    }
    .itemTips {
      font-size: 16px;
      color: #ff7727;
      padding-left: 10px;
    }
    .tableLine {
      display: flex;
      align-items: center;
      // padding: 13px 0;
    }
    .tableLine>div{
      display: flex;
      align-items: center;
      padding: 13px 0;
    }
    .tableLine {
      .table1 {
        width: 250px;
      }
      .table2 {
        width: 220px;
      }
      .table3 {
        width: 220px;
      }
      .table4 {
        width: 220px;
      }
      .table5 {
        width: 220px;
      }
      .table6 {
        width: 300px;
        text-align: center;
      }
    }
    .tableTitle {
      background: #fbfcfa;
      border-bottom: 1px solid #ebeef5;
    }
  }
}
.seaMoney {
  background: #fff;
  text-align: right;
  // padding: 20px;
  // margin: 20px 0;
  .seaTitle {
    border-bottom: 1px solid #ccc;
    padding-bottom: 20px;
    font-size: 18px;
    font-weight: 900;
  }
  .searchBox {
    margin-top: 20px;
    display: flex;
    align-items: center;
    .flex {
      display: flex;
      align-items: center;
    }
  }
}
.chooseItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .itemtitle {
    font-size: 14px;
  }

  .switch {
    height: 50px;
  }
}
.booking {
  background-color: #91be42;
  border-color: #91be42;
}
.content_area {
  background: #f3f6f8;
  min-height: 83vh;
}
.title {
  display: flex;
  height: 88px;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
  padding-left:24px;
  border-bottom:8px solid #f2f2f2;
  .titleText {
    width: 98%;
    height: 22px;
    font-size: 16px;
    font-weight: 800;
    color: #0C263F;
    line-height: 22px;
  }
  .booking {
    background-color: #91be42;
    border-color: #91be42;
  }
}
.header {
  height: 200px;
  padding: 24px 0;
  background: #fff;
  .heraderTop {
    display: flex;
    justify-content: center;
    text-align: center;

    .div1,.div3 {
      max-width: 400px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .div2 {
      // max-width: 250px;
      margin: 0 20px;
    }
    .portEn {
      color: #91be42;
      font-size: 24px;
      font-weight: 800;
      // max-width: 200px;
      margin-right: 4px;
    }
    .portZh {
      font-size: 18px;
      color: rgba(0, 0, 0, 0.65);
      font-weight: 400;
      line-height: 33px;
      // max-width: 120px;
    }
    .line {
      width: 150px;
      height: 1px;
      background: #cdcdcd;
      margin: 5px auto;
      position: relative;
      overflow: visible;
      .jiantou {
        position: absolute;
        color: #cdcdcd;
        right: -1px;
        top: -10px;
      }
    }
    .trans_port {
      color: #333333;
      font-size: 16px;
    }
    div {
      overflow: hidden;
      white-space: nowrap !important;
      text-overflow: ellipsis;
    }
  }
  // .heraderButtom {
  //   width: 100%;
  //   display: flex;
  //   flex-direction: column;
  //   justify-content: center;
  //   padding-left: 50px;
  //   .heraderButtom_line{
  //       display: flex;
  //       justify-content: center;
  //       .ship_item_msg{
  //         height: 22px;
  //         font-size: 14px;
  //         font-weight: 400;
  //         color: #3C5058;
  //         line-height: 22px;
  //         margin-right:40px;
  //       }

  //   }
  //   div {
  //     overflow: hidden;
  //     white-space: nowrap !important;
  //     text-overflow: ellipsis;
  //   }
  //   span {
  //     color: #298df8;
  //     margin-left: 4px;
  //   }
  // }
}
.content {
  background: #f3f6f8;
  display: flex;
  flex-wrap: wrap;
  padding: 0 20px 20px;
  justify-content: space-between;
  //min-height: 500px;
  .item {
    width: 49%;
    background: #ffffff;
    margin-top: 16px;
    .costTitle {
      font-weight: 800;
      color: #1f292e;
      font-size: 16px;
      padding: 20px;
    }
    .line {
      height: 1px;
      width: 100%;
      background: #dcdfe6;
    }
    .costContent {
      padding: 20px;
      .costItem {
        display: flex;
        justify-content: space-between;
        .costItemTitle {
          color: #1f292e;
        }
        .costItemContent {
          color: #f7a350;
          font-size: 18px;
          font-weight: 800;
          span {
            font-size: 14px;
            color: #1f292e;
            font-weight: 400;
            margin-left: 4px;
          }
        }
        .itRemarks {
          margin-top: 6px;
          color: #999999;
        }
        .code {
          font-size: 12px;
          color: #999999;
        }
      }
      .constItem2 {
        margin-top: 20px;
        color: #f46401;
      }
    }
  }
}
.goLogin {
  text-align: center;
  color: #298df8;
  width: 100%;
  padding: 20px 0;
}
.item2Text {
  padding-left: 20px;
  padding-bottom: 20px;
  margin-top: 10px;
  color: #333333;
}
.pageHint {
  margin-top: 10px;
  margin-left: 15px;
  padding: 0px 0 15px 15px;
  color: red;
}
.mohu {
  color: transparent;
  -moz-user-select: none; //火狐
  -webkit-user-select: none; //webkit浏览器（Chrome，Opera，Safari等）
  -ms-user-select: none; //IE10
  -khtml-user-select: none; //早期浏览器
  user-select: none;
  text-shadow: #000 0 0 8px;
}
.page_tips{
  margin:26px 0;
  display: flex;
  align-items: center;
  i{
    color: #FAAD14;
    font-size:30px;
    padding-right:9px;
  }
  span{
    height: 24px;
    font-size: 16px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.85);
    line-height: 24px;
  }
}
.nodata{
  height: 200px;
  text-align: center;
  line-height: 200px;
  color: #606266;
}
</style>
